/* src/pages/AboutUs.css */
.about-us-page {
    padding: 50px;
    background-color: #eeebe6;
    margin: auto;
}

.about-content {
    max-width: 1200px;
    margin: 0 auto;
}

.company-intro h1 {
    font-size: 2.5rem;
    color: #333333;
    margin-bottom: 30px;
    text-align: center;
}

.intro-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;
}

.text-content {
    flex: 1;
    margin-right: 40px;
}

.image-placeholder {
    flex: 0 0 400px;
    height: 300px;
    background-color: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
}

.image-placeholder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

h2 {
    font-size: 2rem;
    color: #333333;
    margin-bottom: 20px;
}

p {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 15px;
}

.mission-vision {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}

.mission, .vision {
    flex: 1;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.mission {
    margin-right: 20px;
}

.company-info {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.info-item h3 {
    font-size: 1.2rem;
    color: #333333;
    margin-bottom: 10px;
}

.info-item p {
    font-size: 1rem;
    color: #555;
}

.credit-code {
    font-family: monospace;
    font-size: 1.1rem;
    color: #0066cc;
}

/* 响应式样式 */
@media (max-width: 1024px) {
    .about-us-page {
        padding: 40px;
    }

    .intro-content {
        flex-direction: column;
    }

    .text-content {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .image-placeholder {
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
    }
}

@media (max-width: 768px) {
    .about-us-page {
        padding: 30px;
    }

    .company-intro h1 {
        font-size: 2.2rem;
    }

    h2 {
        font-size: 1.8rem;
    }

    .mission-vision {
        flex-direction: column;
    }

    .mission {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .info-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .about-us-page {
        padding: 20px;
    }

    .company-intro h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.5rem;
    }

    .image-placeholder {
        height: 200px;
    }

    .info-grid {
        grid-template-columns: 1fr;
    }

    .info-item {
        text-align: center;
    }
}