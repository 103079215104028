.footer {
    background-color: #1a1a1a; /* Darker background color */
    color: #fff;
    padding: 50px 20px;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-column {
    flex: 1;
    margin-right: 20px;
  }
  
  .footer-column h4 {
    margin-bottom: 20px;
    font-size: 18px;
    color: #333333;
  }
  
  .footer-column ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-column ul li {
    margin-bottom: 10px;
  }
  
  .footer-column ul li a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer-column ul li a:hover {
    color: #333333;
  }
  
  .footer-logo {
    width: 80px;
    margin-bottom: 10px;
  }
  
  .about-text {
    color: #ccc;
  }
  
  .social-icons a {
    font-size: 24px;
    color: #fff;
    margin-right: 15px;
    text-decoration: none;
  }

  .ft-link-copy {
    color: #fff;
    text-decoration: none;
  }
  
  .social-icons a:hover {
    color: #333333;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 30px;
    border-top: 1px solid #555;
    padding-top: 20px;
  }
  
  .footer-bottom p {
    margin: 0;
  }
  
  /* Hide footer on mobile */
  @media (max-width: 768px) {
    .footer {
      display: none;
    }
  }
  