/* Product Section Styling */
.product-section {
    padding: 1rem 10rem;
    background-color: #eeebe6;
    text-align: center;
  }

  .products-title {
    text-transform: uppercase;
    color: #a99b83;
    font-size: 30px;
    text-align: center;
    padding: 0 20rem;
    line-height: 1;
  }
  
  .product-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    justify-items: center;
  }

  .border-right {
    border-right: 2px solid #a99b837a;
  }
  
  .product-box {
    background-color: transparent;
    padding: 20px;
    text-align: center;
  }
  
  .product-image-container {
    margin-bottom: 15px;
  }
  
  .product-image {
    max-width: 100%;
    height: auto;
    border-radius: 1rem;
  }
  
  .product-titles {
    font-size: 18px;
    font-weight: 700;
    color: #663333;
    text-transform: uppercase;
    margin-bottom: 5px;
    display: block;
    text-decoration: none;
  }
  
  .product-subtitle {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
  }
  
  .product-price {
    font-size: 16px;
    font-weight: 700;
    color: #333;
    margin-bottom: 15px;
  }
  
  .view-details-button {
    display: inline-block;
    padding: 8px 16px;
    background-color: transparent;
    color: #663333;
    border: 2px solid #663333;
    border-radius: 5px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .view-details-button:hover {
    background-color: #663333;
    color: #fff;
  }

  
 /* Favorite Product Section Styling */
.favorite-products-title {
  margin-top: 50px;
  margin-bottom: 20px;
  justify-content: center;
  display: flex;
  font-size: 30px;
}
.favorite-products-title h2 {
  text-transform: uppercase;
  color: #a99b83;
  text-align: center;
}

.favorite-product-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
  justify-items: center;
  margin-bottom: 3rem;
}

.favorite-product-box {
  background-color: #e3d6cc;
  padding: 20px;
  position: relative;
  text-align: center;
  /* border: 1px solid #e0e0e0; */
  width: 100%;
  max-width: 300px; /* Sets a maximum width to ensure consistency */
}

.favorite-product-badge {
  position: absolute;
  top: 15px;
  left: 15px;
  /* background-color: #fff; */
  padding: 5px 10px;
  border-radius: 50px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 5px;
}

.special-icon {
  width: 60px;
  height: auto;
}

.favorite-product-image-container {
  margin-bottom: 15px;
}

.favorite-product-image {
  max-width: 75%;
  height: auto;
  border-radius: 1rem;
}

.favorite-product-details {
  margin-top: 15px;
}

.favorite-product-title {
  font-size: 18px;
  font-weight: 700;
  color: #663333;
  text-transform: uppercase;
  margin-bottom: 5px;
  display: block;
  text-decoration: none;
}

.favorite-product-subtitle {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.favorite-product-info {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.favorite-product-price {
  font-size: 16px;
  font-weight: 700;
  color: #333;
  margin-bottom: 15px;
}

/* Same View Details Button Styling */
.view-details-button {
  display: inline-block;
  padding: 8px 16px;
  background-color: transparent;
  color: #663333;
  border: 2px solid #663333;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.view-details-button:hover {
  background-color: #663333;
  color: #fff;
}

/* ----------------For Mobile---------------- */

/* Product Section Styling */
.product-section {
  padding: 1rem 10rem;
  background-color: #eeebe6;
  text-align: center;
}

.products-title, .favorite-products-title {
  text-transform: uppercase;
  color: #a99b83;
  font-size: 30px;
  text-align: center;
  line-height: 1;
}

/* Grid layout (desktop view) */
.product-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  justify-items: center;
}

 .favorite-product-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
  justify-items: center;
}

.product-box, .favorite-product-box {
  text-align: center;
  padding: 20px;
  border-radius: 1rem;
  border: 1px solid #a99b83;
}

/* Hide sliders on desktop */
.mobile-only {
  display: none;
}

/* ---------------Responsive Styles------------------- */
@media (max-width: 1024px) {
  .product-grid {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
  }

  .favorite-product-grid {
    grid-template-columns: 1fr;
    justify-content: center; /* Center align the products */
  }

  .favorite-product-box {
    width: 90%; /* Increase the width on mobile */
    max-width: 400px; /* Add max-width to make the product boxes larger but controlled */
  }
}

@media (max-width: 768px) {
  .product-grid {
    grid-template-columns: 1fr;
    justify-content: center;
  }
  .border-right {
      display: none;
  }
  .products-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
  }

  .favorite-product-box {
    width: 90% !important;
    padding: 10px 5px;
    background-color: #fff;
    border-radius: 1rem;
  }
  .favorite-product-image {
    margin-left: 0rem;
    max-width: 100%;
}
  .product-section {
    padding: 1rem 0;
  }

  /* -----------Mobile webapp----------- */
  .desktop-only {
    display: none;
  }

  /* Show sliders only on mobile */
  .mobile-only {
    display: block;
    margin-bottom: 4rem;
  }
  .products-heading {
    font-size: 20px;
  }
  .favorite-products-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}
.product-price {
  font-size: 0.8rem !important;
}
.favorite-product-title {
  font-size: 15px;
}
}

@media (max-width: 480px) {
  .product-box {
    width: 90% !important;
    padding: 5px 0px;
    background-color: #fff;
    border-radius: 1rem;
  }

  .slick-list {
    padding: 15px 0px;
  }

  .product-titles {
    font-size: 11px;
  }

  .product-image {
    max-width: 100%;
    margin-left: 0rem;
  }
}
/* ------------------------------ */
