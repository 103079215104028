/* Category Section Styling */
.category-section {
  padding: 40px 0;
  background-color: #eeebe6;
  text-align: center;
}

.section-title {
  text-transform: uppercase;
  color: #a99b83;
  font-size: 42px;
  text-align: left; /* Align title to the left */
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 3rem;
}

.view-all-link {
  color: #333333;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-decoration: none;
  display: none;
}

.arrow-icon {
  margin-left: 5px;
}

/* Categories Layout */
.categories-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.category-box {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 200px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  text-align: center;
}

.category-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.category-image-container {
  margin-bottom: 15px;
}

.category-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

.category-title {
  font-size: 18px;
  font-weight: 500;
  color: #333;
}

/* Hide grid layout and show carousel for mobile devices */
.categories-container-mobile {
  display: none;
}

@media (max-width: 768px) {
  .categories-container {
    display: none; /* Hide grid layout */
  }

  .categories-container-mobile {
    display: block; /* Show mobile carousel */
    padding: 0 0px;
  }

  .category-box {
    width: 150px;
  }

  .category-title {
    font-size: 15px;
  }

  .category-image {
    max-width: 75%;
  }

  .section-title {
    font-size: 20px;
  }

  /* Section Header Alignment for Mobile */
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
  }

  .view-all-link {
    font-size: 15px;
    display: flex;
    align-items: center;
  }

  .arrow-icon {
    font-size: 12px;
    margin-left: 5px;
  }
}

@media (max-width: 480px) {
  .category-box {
    width: 85% !important;
    padding: 0px 0px;
  }

  .slick-list {
    padding: 15px 0px;
  }

  .category-title {
    font-size: 12px;
    text-align: left;
    font-weight: 700;
    padding: 0.4rem 1rem;
  }

  .category-image {
    max-width: 100%;
    margin-left: 0rem;
    border-radius: 10px 10px 0px 0px;
  }
}
