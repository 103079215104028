html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}


.wine-slider {
  width: 100%;
  margin: 0 auto;
  padding: 30px 0;
  background-color: #eeebe6;
}

.slide {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.left-content {
  flex: 1;
  padding-right: 0px;
  padding-left: 5rem;
}

.center-image {
  flex: 0 1 300px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.center-image img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.right-content {
  padding-right: 5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 20px;
}

.right-content h3 {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 20px;
}

.right-content select {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  width: 100%;
}

.add-to-cart {
  background-color: #333333;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-to-cart:hover {
  background-color: #336666;
}

/* Make the slider responsive */
@media (max-width: 768px) {
  .slide {
    flex-direction: column;
    align-items: center;
  }

  .left-content,
  .right-content {
    text-align: center;
    padding: 0 0 20px 0;
  }

  .left-content {
    padding: 0 20px;
  }

  .right-content {
    padding-right: 0;
  }

  .right-content h3 {
    font-size: 30px;
  }

  .center-image {
    margin-bottom: 20px;
  }

  .add-to-cart {
    width: 100%; /* Full width for mobile */
  }
}
@media (max-width: 480px) {
  .center-image img {
    max-width: 50%;
  }
  .wine-slider {
    background-color: #f1e7d5;
  }
}