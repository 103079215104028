/* Existing styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5rem;
  background-color: #FFEBEE;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 4px;
}

.header-logo {
  width: 50px;
  margin-bottom: 0px;
}

.drawer {
  display: none;
}

nav.menu a {
  margin-left: 2rem;
  text-decoration: none;
  color: #ffffff;
  background-color: #663333;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid;
}

nav.menu a:hover {
  background-color: transparent;
  color: #663333;
  border: 1px solid;
}

svg.icon {
  margin-left: 1rem;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.logo a {
  text-decoration: none;
}

.hamburger-icon {
  display: none; /* Hide on desktop */
  font-size: 24px;
  cursor: pointer;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .icons {
    display: none; /* Hide icons on mobile */
  }
  
  .menu {
    display: none; /* Hide menu on mobile */
  }
  
  .logo {
    margin: 0 auto; /* Center the logo */
    text-align: center;
    width: 100%; /* Ensure the logo takes full width to center it */
  }
  
  .header {
    justify-content: center; /* Align the entire header content centrally */
    position: relative; /* Ensure drawer aligns correctly */
    padding: 1rem;
    background-color: #eeebe6;
    box-shadow: none;
    margin-bottom: 1px;
  }
  
  .hamburger-icon {
    display: block; /* Show hamburger icon on mobile */
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .drawer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    background-color: #eeebe6;
    transform: translateX(-100%); /* Initially hidden off-screen to the left */
    transition: transform 0.3s ease-in-out; /* Smooth slide-in transition */
    z-index: 1000;
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
    overflow-y: auto; /* Allow scrolling if content is too long */
  }
  
  .drawer.open {
    transform: translateX(0); /* Slide the drawer into view */
  }
  
  .drawer-section {
    margin-bottom: 1.5rem;
  }
  
  .drawer-section h4 {
    color: #663333;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #ccc;
    padding-bottom: 0.5rem;
  }
  
  .drawer-section a {
    display: block;
    padding: 0.5rem 0;
    text-decoration: none;
    color: #663333;
    font-size: 1rem;
  }
  
  .drawer-section.main-nav a {
    font-size: 1.2rem;
    padding: 0.7rem 0;
  }
  
  .drawer .social-icons {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
  }
  
  .drawer .social-icons a {
    font-size: 1.5rem;
    color: #663333;
  }
  
  /* Overlay for when the drawer is open */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 999; /* Below drawer but above other content */
  }
}