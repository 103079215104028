/* src/pages/FAQ.css */
.faq-page {
    padding: 50px;
    background-color: #eeebe6;
    margin: auto;
}

.faq-content {
    max-width: 800px;
    margin: 0 auto;
}

.faq-page h1 {
    font-size: 2.5rem;
    color: #333333;
    margin-bottom: 30px;
    text-align: center;
}

.faq-intro {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 30px;
    text-align: center;
}

.faq-list {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.faq-item {
    border-bottom: 1px solid #eee;
}

.faq-item:last-child {
    border-bottom: none;
}

.faq-question {
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    padding: 20px;
    font-size: 1.1rem;
    color: #333;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-icon {
    font-size: 1.5rem;
    color: #666;
}

.faq-answer {
    padding: 0 20px 20px;
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
}

/* 响应式样式 */
@media (max-width: 768px) {
    .faq-page {
        padding: 30px;
    }

    .faq-page h1 {
        font-size: 2rem;
    }

    .faq-question {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .faq-page {
        padding: 20px;
    }

    .faq-page h1 {
        font-size: 1.8rem;
    }

    .faq-intro {
        font-size: 0.9rem;
    }

    .faq-question {
        padding: 15px;
    }

    .faq-answer {
        padding: 0 15px 15px;
        font-size: 0.9rem;
    }
}