/* src/pages/PrivacyPolicy.css */
.privacy-policy-page {
    padding: 50px;
    background-color: #eeebe6;
    margin: auto;
}

.privacy-policy-content {
    max-width: 800px;
    margin: 0 auto;
    background-color: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.privacy-policy-page h1 {
    font-size: 2.5rem;
    color: #333333;
    margin-bottom: 30px;
    text-align: center;
}

.policy-intro {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 30px;
    text-align: center;
    font-style: italic;
}

.privacy-policy-page section {
    margin-bottom: 30px;
}

.privacy-policy-page h2 {
    font-size: 1.5rem;
    color: #333333;
    margin-bottom: 15px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
}

.privacy-policy-page p {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 15px;
}

.privacy-policy-page ul {
    padding-left: 20px;
    margin-bottom: 15px;
}

.privacy-policy-page li {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 10px;
}

.privacy-policy-page strong {
    color: #333;
}

.policy-footer {
    font-size: 0.9rem;
    color: #777;
    text-align: center;
    margin-top: 40px;
    font-style: italic;
}

/* 响应式样式 */
@media (max-width: 768px) {
    .privacy-policy-page {
        padding: 30px;
    }

    .privacy-policy-content {
        padding: 30px;
    }

    .privacy-policy-page h1 {
        font-size: 2rem;
    }

    .privacy-policy-page h2 {
        font-size: 1.3rem;
    }
}

@media (max-width: 480px) {
    .privacy-policy-page {
        padding: 20px;
    }

    .privacy-policy-content {
        padding: 20px;
    }

    .privacy-policy-page h1 {
        font-size: 1.8rem;
    }

    .privacy-policy-page h2 {
        font-size: 1.2rem;
    }

    .policy-intro {
        font-size: 1rem;
    }

    .privacy-policy-page p, .privacy-policy-page li {
        font-size: 0.9rem;
    }
}