/* src/components/Modal.css */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    cursor: pointer;
    touch-action: none; /* 防止移动设备上的滚动和缩放 */
}

.modal-content {
    background-color: #1a1a1a;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    color: #fff;
    width: 90%;
    max-width: 500px;
    cursor: default;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-content p {
    margin-bottom: 15px;
    font-size: 18px;
}

.modal-content button {
    padding: 10px 20px;
    background-color: #333333;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    touch-action: manipulation; /* 优化移动设备上的点击 */
}

.modal-content button:hover {
    background-color: #555;
}

/* 移动设备优化 */
@media (max-width: 768px) {
    .modal-content {
        padding: 15px;
        width: 95%;
    }

    .modal-content p {
        font-size: 16px;
    }

    .modal-content button {
        padding: 12px 20px;
        font-size: 18px; /* 增大字体大小，便于触摸 */
    }
}