/* src/pages/ContactUs.css */
.contact-us-page {
    padding: 50px;
    background-color: #eeebe6;
    margin: auto; /* Center the content */
}

.contact-content {
    display: flex; /* Use flexbox for layout */
    justify-content: space-between; /* Space between items */
    margin-bottom: 30px; /* Spacing below content */
    flex-wrap: wrap; /* Allow items to wrap to the next line on smaller screens */
}

.contact-info {
    flex: 1; /* Take up equal space */
    margin-right: 20px; /* Space between info and form */
    padding: 20px; /* Inner padding */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    min-width: 300px; /* Ensure minimum width */
}

.contact-info h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: #333333;
}

.info-item {
    margin-bottom: 15px;
}

.info-item h3 {
    margin: 0;
    color: #333333;
}

.contact-form {
    flex: 1; /* Take up equal space */
    background: #fff; /* White background */
    padding: 20px; /* Inner padding */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    min-width: 300px; /* Ensure minimum width */
    margin-right: 20px;
}

.contact-form h2 {
    font-size: 2rem;
    margin-bottom: 15px;
    color: #333333;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold; /* Bold labels */
}

.form-group input,
.form-group textarea {
    width: 100%; /* Make input/textarea take full width */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem; /* Consistent font size */
    box-sizing: border-box; /* Prevent inputs from overflowing */
}

.form-group textarea {
    resize: vertical; /* Allow vertical resizing only */
    min-height: 100px; /* Minimum height for the textarea */
}

.submit-button {
    padding: 10px 15px;
    background-color: #333333; /* Button color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition */
    font-size: 1rem; /* Increased font size */
    width: 100%; /* Full width button */
}

.submit-button:hover {
    background-color: #ff4500; /* Darker shade on hover */
}

.contact-map {
    margin-top: 30px;
    padding-bottom: 6rem;
}

.contact-map h2 {
    font-size: 2rem;
    margin-bottom: 15px;
    color: #333333;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .contact-content {
        flex-direction: column; /* Stack columns on smaller screens */
    }

    .contact-form {
        order: 1; /* Move the form to the top */
    }

    .contact-info {
        order: 2; /* Move the info below the form */
        margin-bottom: 20px; /* Space between stacked elements */
    }

    .contact-info h1,
    .contact-form h2 {
        font-size: 2rem; /* Reduce font size for headings */
    }
}

@media (max-width: 480px) {
    .contact-us-page {
        padding: 20px; /* Reduce padding for smaller devices */
    }

    .contact-info h1 {
        font-size: 1.8rem; /* Further reduce font size for headings */
    }

    .contact-form h2 {
        font-size: 1.5rem; /* Further reduce font size for headings */
    }

    .form-group input,
    .form-group textarea {
        font-size: 0.9rem; /* Decrease font size of inputs */
    }

    .submit-button {
        padding: 8px 12px; /* Adjust padding for buttons */
        font-size: 0.9rem; /* Decrease button font size */
        width: 100%; /* Ensure full width for mobile view */
    }

    .contact-map h2 {
        font-size: 1.5rem; /* Decrease map heading size */
    }

    .contact-info,
    .contact-form {
        padding: 15px; /* Decrease padding on mobile */
        margin-bottom: 3rem;
    }

    .form-group {
        padding: 0; /* Remove additional padding on mobile */
    }
}
