/* src/pages/Terms.css */
.terms-page {
    padding: 50px;
    background-color: #eeebe6;
    margin: auto;
}

.terms-content {
    max-width: 800px;
    margin: 0 auto;
    background-color: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.terms-page h1 {
    font-size: 2.5rem;
    color: #333333;
    margin-bottom: 30px;
    text-align: center;
}

.terms-intro {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 30px;
    text-align: center;
    font-style: italic;
}

.terms-page section {
    margin-bottom: 30px;
}

.terms-page h2 {
    font-size: 1.5rem;
    color: #333333;
    margin-bottom: 15px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
}

.terms-page p {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 15px;
}

.terms-page ul {
    padding-left: 20px;
    margin-bottom: 15px;
}

.terms-page li {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 10px;
}

.terms-footer {
    font-size: 0.9rem;
    color: #777;
    text-align: center;
    margin-top: 40px;
    font-style: italic;
}

/* 响应式样式 */
@media (max-width: 768px) {
    .terms-page {
        padding: 30px;
    }

    .terms-content {
        padding: 30px;
    }

    .terms-page h1 {
        font-size: 2rem;
    }

    .terms-page h2 {
        font-size: 1.3rem;
    }
}

@media (max-width: 480px) {
    .terms-page {
        padding: 20px;
    }

    .terms-content {
        padding: 20px;
    }

    .terms-page h1 {
        font-size: 1.8rem;
    }

    .terms-page h2 {
        font-size: 1.2rem;
    }

    .terms-intro {
        font-size: 1rem;
    }

    .terms-page p, .terms-page li {
        font-size: 0.9rem;
    }
}