.mobile-footer {
  display: none;
}

@media (max-width: 768px) {
  .mobile-footer {
    display: flex;
    justify-content: space-around;
    padding: 10px;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.1);
  }

  .icon {
    font-size: 24px;
    color: #ccc; /* Default unfilled color */
    cursor: pointer;
  }

  .icon.active {
    color: #333333; /* Filled color when active */
  }
  svg.icon {
    margin-left: -1rem;
  }
}
