.product-detail-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px; /* Reduced padding for mobile */
  background-color: #eeebe6;
}

.product-detail-wrapper {
  display: flex;
  flex-direction: column; /* Stack items vertically on mobile */
  gap: 20px;
}

.main-image img {
  width: 100%; /* Make the main image responsive */
  height: auto;
}

.description-image {
  max-width: 100%;
  height: auto;
  margin: 10px 0;
}

.thumbnails img {
  width: 80px;
  height: 80px;
  cursor: pointer;
  border: 2px solid transparent;
  padding: 0.5rem;
}

.thumbnails img.active {
  border-color: #ddd;
  border-radius: 1rem;
  padding: 0.5rem;
}

.product-info {
  flex: 1;
  text-align: center; /* Center text on mobile */
}

.product-title {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #663333;
}

.product-price {
  font-size: 1.5rem;
  color: #b12704;
  margin-bottom: 20px;
}

.product-description {
  margin-bottom: 20px;
}

.product-quantity {
  margin-bottom: 20px;
}

.product-quantity label {
  margin-right: 10px;
}

.product-quantity input {
  width: 60px;
}

.add-to-cart-button {
  padding: 10px 15px;
  background-color: #663333;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 0.5rem;
}

.product-tabs {
  margin-top: 30px;
}

.product-tabs button {
  margin-right: 10px;
  padding: 10px 15px;
  border: none;
  background-color: #f7f7f7;
  cursor: pointer;
}

.product-tabs button.active {
  background-color: #bb8834;
  color: white;
}

.tab-content {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .product-detail-wrapper {
    flex-direction: row; /* Row layout on tablet and larger */
  }
  .thumbnails {
    display: flex;
    gap: 8px;
    margin-top: 10px;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0px;
}
  .product-images {
    width: 100%;
}

  .main-image img {
    width: 60%;
  }

  .thumbnails {
    justify-content: flex-start; /* Align thumbnails left on larger screens */
  }

  .product-info {
    text-align: left;
  }
}

@media (min-width: 1024px) {
  .product-detail-container {
    padding: 85px;
  }
  .thumbnails {
    display: flex;
      gap: 20px;
      margin-top: 10px;
      justify-content: center !important;
      flex-wrap: wrap;
      padding: 20px 10px;
  }
  .main-image {
    border: 1px solid #ddd;
    border-radius: 1rem;
    text-align: center;
    padding: 1rem;
}
  .product-images {
    width: 50%;
}

  .main-image img {
    width: 50%; /* Adjust image size on larger screens */
  }

  .product-info {
    text-align: left; /* Align text to left on desktop */
  }
}

@media (max-width: 480px) {
  .thumbnails {
    margin-top: 1rem;
}
}
