/* src/components/ProductDetail/RelatedProductsSlider.css */
.related-products-slider {
    margin: 5rem 0;
  }
  
  .related-product {
    padding: 10px;
    text-align: center;
  }
  
  .related-product img {
    width: 70%; /* Make images responsive */
    height: auto;
    margin-left: 2.3rem;
    border-radius: 1rem;
  }
  
  .related-product h3 {
    font-size: 1.2rem;
    margin: 10px 0;
  }
  
  .related-product p {
    font-size: 1rem;
    color: #888;
  }

  .add-to-cart-btn {
    display: inline-block;
    padding: 8px 16px;
    background-color: transparent;
    color: #663333;
    border: 2px solid #663333;
    border-radius: 5px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .slick-prev:before, .slick-next:before {
    color: #663333;
  }
  
  @media (max-width: 480px) {
    .product-detail-container {
      padding: auto;
    }
    .related-product img {
      margin-left: 0.5rem;
      width: 95%;
    }
  }
